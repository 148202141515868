import { Box, Grid, Typography, Button } from "@mui/material";

function ContentArticles({
  sections,
  introParagraph,
  outroParagraph,
  ctaText,
  ctaAction,
}) {
  return (
    <Box>
      {/* Sección de Introducción (100%) */}
      <Grid container sx={{ padding: { xs: "10px", md: "15px" } }}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              color: "#ECEAEF", // Cambiar el color del texto a ECEAEF
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.2,
              fontSize: { xs: "14px", md: "16px" }, // Responsividad
              marginTop: { xs: "10px", md: "20px" },
            }}
          >
            {introParagraph}
          </Typography>
        </Grid>
      </Grid>

      {/* Secciones dinámicas */}
      {sections.map((section, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            padding: { xs: "10px", md: "15px" },
            marginTop: { xs: "10px", md: "20px" },
          }}
          key={index}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              order: { xs: 1, sm: index % 2 === 0 ? 1 : 2 },
            }}
          >
            <Box sx={{ width: { xs: "100%", md: "80%" } }}>
              <img
                src={section.imageSrc}
                alt={section.imageAlt}
                style={{
                  borderRadius: "8px",
                  width: "100%", // Para dispositivos móviles
                  objectFit: "cover", // Asegura que la imagen mantenga proporción
                }}
              />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{ order: { xs: 2, sm: index % 2 === 0 ? 2 : 1 } }}
          >
            <Box sx={{ width: {xs: "100%", md:"80%"} }}>
              <Typography
                variant="h4"
                sx={{
                  color: "#B51AD8",
                  fontWeight: "bold",
                  fontFamily: "Poppins, sans-serif",
                  fontSize: { xs: "22px", sm: "30px" }, // Responsividad
                  marginBottom: "16px",
                  textTransform: "uppercase",
                }}
              >
                {section.title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#ECEAEF", // Cambiar el color del texto a ECEAEF
                  fontFamily: "Poppins, sans-serif",
                  lineHeight: 1.2,
                  fontSize: { xs: "14px", md: "16px" },
                }}
              >
                {section.description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      ))}

      {/* Sección de conclusión (100%) */}
      <Grid
        container
        sx={{
          padding: { xs: "10px", md: "15px" },
          marginTop: { xs: "10px", md: "20px" },
        }}
      >
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              color: "#ECEAEF", // Cambiar el color del texto a ECEAEF
              fontFamily: "Poppins, sans-serif",
              lineHeight: 1.2,
              fontSize: { xs: "14px", md: "16px" },
              marginTop: { xs: "10px", md: "20px" },
            }}
          >
            {outroParagraph}
          </Typography>
        </Grid>
      </Grid>

      {/* Llamada a la Acción (100%) */}
      <Grid
        container
        justifyContent="center"
        sx={{
          padding: { xs: "10px", md: "15px" },
          marginTop: { xs: "20px", md: "40px" },
        }}
      >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            sx={{
              padding: "10px 20px",
              backgroundColor: "#ECEAEF",
              color: "#0E0610",
              borderRadius: "25px",
              "&:hover": {
                backgroundColor: "#B51AD8", // Cambiar el fondo a color morado en hover
                color: "white", // Cambiar el color del texto a blanco
              },
            }}
            onClick={ctaAction}
          >
            {ctaText}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContentArticles;
