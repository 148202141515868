import React from "react";
import Navbar from "../navbarHomeGral/navbarGral";
import HomeService from "../servicios/components/HomeService";
import SectionCard from "../servicios/components/SectionCards";
import Footer from "../footer/page";
import { Box,} from "@mui/material";

const Servicios = () => {
  return (
    <Box>
        <Navbar />
        <HomeService />
        <SectionCard />
      <Footer />
    </Box>
  );
};

export default Servicios;
