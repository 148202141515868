import React, { useState } from "react";
import { Button, TextField, Grid } from "@mui/material";

const RecogerCorreoLead = () => {
  const [email, setEmail] = useState("");

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: { xs: "40px", sm: "50px" },
        padding: "0px 20px",
      }}
    >
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          borderRadius: "25px",
          border: "1px solid #FFFFFF",
          padding: "2px",
          width: { xs: "90%", sm: "70%", md: "50%", lg: "38%" },
          zIndex: 999999,
        }}
      >
        <TextField
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Escribe tu correo y descarga la guía"
          variant="outlined"
          sx={{
            input: {
              color: "#FFFFFF", // Color del texto
              borderRadius: "25px",
              padding: "10px",
            },
            "& .MuiOutlinedInput-root": {
              border: "none",
              "& fieldset": {
                border: "none",
              },
              "&:hover fieldset": {
                border: "none",
              },
              "&.Mui-focused fieldset": {
                border: "none", // Elimina el borde azul
                boxShadow: "none",
              },
            },
          }}
          fullWidth
        />

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#6F4CE0",
            color: "#FFFFFF",
            width: 160,
            borderRadius: "25px",
            cursor: "pointer",
            transition: "background-color 0.3s",
            fontSize: { xs: "15px", sm: "15px" },
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: "#3E1F7E",
            },
          }}
          onClick={(e) => e.preventDefault()} // Evitar el envío de datos
        >
          DESCARGAR
        </Button>
      </Grid>
    </Grid>
  );
};

export default RecogerCorreoLead;
