import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import TypographyColumns from "../../components/cabeceras/vertical/verticalUno/VerticalUno";

const typographyData = [
  {
    variant: "body1",
    color: "#B51AD8",
    textTransform: "uppercase",
    fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" }, // Ajuste responsivo de tamaño
    maxWidth: "40%",
    text: "ALGUNAS RESEÑAS",
    fontWeight: 500,
  },
  {
    variant: "body1",
    textTransform: "uppercase",
    fontSize: { xs: "36px", sm: "42px", md: "52px", lg: "52px" }, // Ajuste responsivo de tamaño
    maxWidth: { xs: "80%", md: "60%" },
    text: "OPINIONES DE NUESTROS CLIENTES",
    fontWeight: "bold",
    lineHeight: {
      xs: "45px", // para pantallas extra pequeñas
      sm: "50px", // para pantallas pequeñas
      md: "58px", // para pantallas medianas (el más grande)
      lg: "58px", // para pantallas grandes
      xl: "58px", // para pantallas extra grandes
    },
  },
];
// Datos de las reseñas
const row1 = [
  {
    title: "Salud Transformada",
    rating: "★★★★★",
    paragraph:
      "Gracias a Hitpoly, mis campañas ayudaron a mejorar la vida de mis pacientes.",
  },
  {
    title: "Empoderamiento al Máximo",
    rating: "★★★★★",
    paragraph:
      "Hitpoly me ayudó a empoderar a más mujeres a través de mis redes.",
  },
  {
    title: "Negocio en Crecimiento",
    rating: "★★★★☆",
    paragraph:
      "Con sus estrategias, mi negocio creció equilibradamente sin sacrificios.",
  },
  {
    title: "Ingresos Estables",
    rating: "★★★★★",
    paragraph:
      "Con Hitpoly, logré llegar a los 25,000 soles mensuales en mi negocio.",
  },
  {
    title: "Estrategias Claras",
    rating: "★★★★☆",
    paragraph:
      "Gracias a su guía, nuestras campañas se volvieron claras y efectivas.",
  },
  {
    title: "Visible y Confiable",
    rating: "★★★★★",
    paragraph: "Nos posicionaron como líderes en medicina antienvejecimiento.",
  },
];

const row2 = [
  {
    title: "Crecimiento Rápido",
    rating: "★★★★★",
    paragraph:
      "Hitpoly logró atraer clientes apasionados con sus campañas efectivas.",
  },
  {
    title: "Servicios Potenciados",
    rating: "★★★★☆",
    paragraph:
      "Nuestra visibilidad creció increíblemente con las estrategias de Hitpoly.",
  },
  {
    title: "Servicio Excepcional",
    rating: "★★★★★",
    paragraph:
      "Duplicamos nuestra clientela gracias a sus excelentes campañas.",
  },
  {
    title: "Alimentos Saludables",
    rating: "★★★★☆",
    paragraph:
      "Promovimos productos naturales y aumentamos ventas con su ayuda.",
  },
  {
    title: "Gran Retorno de Inversión",
    rating: "★★★★★",
    paragraph: "Hitpoly nos ayudó a alcanzar un ROI increíble en poco tiempo.",
  },
  {
    title: "ROI Mejorado",
    rating: "★★★★★",
    paragraph: "Gracias a Hitpoly, mejoramos continuamente nuestro ROI.",
  },
];

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

// Estilos usando Material-UI
const AppContainer = styled(Box)`
  display: flex;
  margin-top: 80px;
  overflow: hidden;
  max-width: 99vw;
`;

const Wrapper = styled(Box)`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 99vw;
`;

const Marquee = styled(Box)`
  display: flex;
  width: 1700px;
  overflow: hidden;
  user-select: none;
  position: relative;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;

const MarqueeGroup = styled(Box)`
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 50s linear infinite;
  flex-shrink: 0;
  overflow: hidden;
`;

const MarqueeGroup2 = styled(Box)`
  margin-top: 50px;
  overflow: hidden;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  white-space: nowrap;
  width: 100%;
  animation: ${scrollX} 50s linear infinite;
  animation-direction: reverse;
  animation-delay: -3s;
  flex-shrink: 0;
`;

const CajaReviw = styled(Box)`
  position: relative; /* Necesario para posicionar los pseudo-elementos */
  margin-left: 30px;
  overflow: hidden;
  max-width: 300px; /* Limita el ancho para evitar el desbordamiento */
  padding: 12px;
  height: 150px;
  background: rgba(
    0,
    0,
    0,
    0.1
  ); /* Fondo semitransparente para contraste con el degradado */
  border-radius: 0.5rem;
  color: #ffffff;
  overflow-wrap: break-word;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: normal;

  /* Pseudo-elementos para el degradado */
  &::before,
  &::after {
    content: "";
    position: absolute;
    border-radius: 50%;
    background: radial-gradient(
      circle,
      rgba(115, 17, 187, 0.5),
      transparent 60%
    );
    transition: background 0.3s;
    z-index: -1; /* Hace que los pseudo-elementos queden detrás del contenido */
  }

  &::before {
    top: 30px;
    left: -80px;
    width: 200px;
    height: 200px;
    filter: blur(30px);
  }

  &::after {
    bottom: 30px;
    right: -80px;
    width: 200px;
    height: 200px;
    filter: blur(30px);
  }

  /* Efecto en hover (opcional) */
  &:hover::before,
  &:hover::after {
    background: radial-gradient(
      circle,
      rgba(115, 17, 187, 0.8),
      transparent 60%
    );
  }
`;

// Componente que mostrará los datos de las reseñas
const Review = ({ title, rating, paragraph }) => {
  return (
    <CajaReviw>
      <Typography
        variant="h6"
        sx={{
          fontWeight: "400",
          marginBottom: "5px",
          fontSize: "1rem",
        }}
      >
        {title}
      </Typography>
      <Typography variant="body1" sx={{ color: "gold", marginBottom: "5px" }}>
        {rating}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          marginBottom: "5px",
          fontWeight: "300",
          fontSize: ".8rem",
          whiteSpace:
            "normal" /* Asegura que el texto pueda romperse en múltiples líneas */,
          overflowWrap:
            "break-word" /* Asegura que las palabras largas se quiebren */,
        }}
      >
        {paragraph}
      </Typography>
    </CajaReviw>
  );
};

const ServiceReviews = () => {
  return (
    <AppContainer
      sx={{
        width: "100%",
        color: "#fff",
        fontWeight: "bold",
      }}
    >
      <Wrapper
        sx={{
          color: "#fff",
          fontWeight: "bold",
          width: "100%",
        }}
      >
        <TypographyColumns data={typographyData} />
        <Marquee>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <Review
                key={index}
                title={el.title}
                rating={el.rating}
                paragraph={el.paragraph}
              />
            ))}
          </MarqueeGroup>
          <MarqueeGroup>
            {row1.map((el, index) => (
              <Review
                key={index}
                title={el.title}
                rating={el.rating}
                paragraph={el.paragraph}
              />
            ))}
          </MarqueeGroup>
        </Marquee>
        <Marquee>
          <MarqueeGroup2>
            {row2.map((el, index) => (
              <Review
                key={index}
                title={el.title}
                rating={el.rating}
                paragraph={el.paragraph}
              />
            ))}
          </MarqueeGroup2>
          <MarqueeGroup2>
            {row2.map((el, index) => (
              <Review
                key={index}
                title={el.title}
                rating={el.rating}
                paragraph={el.paragraph}
              />
            ))}
          </MarqueeGroup2>
        </Marquee>
      </Wrapper>
    </AppContainer>
  );
};

export default ServiceReviews;
