import { Box, Grid2 } from "@mui/material";
import React from "react";
import NavGral from "../../navbarHomeGral/navbarGral";
import StepCard from "../../../Views/internalPages/diseñoWeb/components/StepCard";
import CardsProyects from "../../../Views/internalPages/diseñoWeb/components/CardsProyects";
import Footer from "../../footer/page";
import TitlePage from "./components/TitlePage";
import ContentArticles from "./components/content/content.jsx";

const DiseñoWeb = ({ title, subtitle, sections, introParagraph, outroParagraph, ctaText, ctaAction }) => {
  return (
    <Box>
      <Grid2>
        <NavGral />

        <TitlePage subtitle={subtitle} title={title} />

        <Box sx={{ padding: {xs: "0px 20px", md: "0px 200px" }}}>
          <ContentArticles 
            sections={sections} // Cambié de sectionsData a sections
            introParagraph={introParagraph}
            outroParagraph={outroParagraph}
            ctaText={ctaText} 
            ctaAction={ctaAction} 
          />
        </Box>

        <StepCard />
        <CardsProyects />

        <Footer />
      </Grid2>
    </Box>
  );
};

export default DiseñoWeb;
