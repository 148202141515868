import React from "react";
import { Card, CardMedia, CardContent, Typography, Button } from "@mui/material";
import ArrowOutward from "@mui/icons-material/ArrowOutward";

const CardDeArticulosMasVistos = ({ title, description, image, link }) => {
  return (
    <Card
      sx={{
        borderRadius: "15px",
        transform: "translateY(-10px)",
        boxShadow: "0 15px 25px rgba(0, 0, 0, 0.2)",
        background: `
          linear-gradient(145deg, rgba(115, 17, 187, 0.1), rgba(115, 17, 187, 0)),
          linear-gradient(45deg, rgba(115, 17, 187, 0.0), rgba(115, 17, 187, 0))
        `,
        display: "flex",
        flexDirection: "column",
        width: "90%",
        maxWidth: "380px",
        height: "550px",
        cursor: "pointer",
        transition: "transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease",
        "&:hover": {
          transform: "translateY(-20px)",
          boxShadow: "0 25px 35px rgba(0, 0, 0, 0.3)",
          background: `
            linear-gradient(145deg, rgba(115, 17, 187, 0.3), rgba(115, 17, 187, 0.1)),
            linear-gradient(45deg, rgba(115, 17, 187, 0.1), rgba(115, 17, 187, 0.05))
          `,
        },
      }}
    >
      <CardMedia
        component="img"
        height="300px"
        image={image}
        alt={title}
        sx={{ objectFit: "cover", transition: "opacity 0.3s ease", "&:hover": { opacity: 0.9 } }}
      />
      <CardContent sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Typography
          variant="h6"
          sx={{
            color: "#FFFFFF",
            fontWeight: "500",
            marginBottom: "10px",
            fontSize: { xs: "16px", sm: "18px", md: "18px" },
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: "#FFFFFF",
            marginBottom: "20px",
            fontSize: { xs: "14px", sm: "16px", md: "14px" },
          }}
        >
          {description}
        </Typography>
        
      </CardContent>
      <Button
          component="a"
          href={link}
          target="_blank"
          variant="contained"
          color="primary"
          sx={{
            backgroundColor: "#6F4CE0",
            marginBottom: "20px",
            marginLeft: "15px",
            width: { xs: "70%", sm: "80%", md: "70%" },
            borderRadius: "20px",
            textTransform: "none",
            fontSize: { xs: "14px", sm: "14px", md: "16px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "background-color 0.3s ease, transform 0.3s ease",
            "&:hover": {
              backgroundColor: "#3E1F7E",
            },
          }}
        >
          Leér más
          <ArrowOutward
            sx={{
              fontSize: { xs: "18px", sm: "18px", md: "22px" },
              marginLeft: "10px",
              transform: "rotate(-0deg)",
            }}
          />
        </Button>
    </Card>
  );
};

export default CardDeArticulosMasVistos;
