import React, { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Logo from "../../assets/logohitpoly.png";
import MenuDeServicios from "./menuDeServicios/menuDeServicios";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

const EfectoSpan = styled("span")(

  ({ width, left }) => ({
    position: "absolute",
    top: 0,
    left: `${left}px`,
    width: `${width}px`,
    height: "100%",
    background: "#6F4CE0",
    borderRadius: "25px",
    transition: "left 0.3s ease, width 0.3s ease",
    zIndex: 1,
  })
);

const EfectoSpanHover = styled("span")(
  ({ width, left }) => ({
    position: "absolute",
    top: 0,
    left: `${left}px`,
    width: `${width}px`,
    height: "100%",
    background: "linear-gradient(45deg, #8A6FFF, #8A6FFF)",
    borderRadius: "25px",
    transition: "left 0.3s ease, width 0.3s ease",
    zIndex: 0,
  })
);

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const [hoverIndex, setHoverIndex] = useState(null);
const [selectedIndex, setSelectedIndex] = useState(null);
const [itemWidths, setItemWidths] = useState([]);
const itemRefs = useRef([]);


  useEffect(() => {
    const updateWidths = () => {
      const widths = itemRefs.current.map(
        (ref) => ref?.getBoundingClientRect().width || 0
      );
      setItemWidths(widths);
    };

    updateWidths();

    window.addEventListener("resize", updateWidths);
    return () => {
      window.removeEventListener("resize", updateWidths);
    };
  }, [navItems]);

  useEffect(() => {
    const activeIndex = navLinks.findIndex(
      (link) => link === location.pathname
    );
    setSelectedIndex(activeIndex);
  }, [location]);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMouseEnter = (index) => {
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setHoverIndex(null);
  };

  const calculateLeft = (index) => {
    return index !== null ? itemRefs.current[index]?.offsetLeft || 0 : 0;
  };

  const drawerList = () => (
    <Box
      sx={{ maxWidth: 250, backgroundColor: "#000000", height: "100%" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List sx={{ padding: "20px 0" }}>
        {navItems.map((item, index) => (
          <ListItem button key={index} sx={{ display: "block", textAlign: "center" }}>
            <Link to={navLinks[index]} style={navLinkStyle}>
              <ListItemText primary={item} sx={{ color: "#FFFFFF", fontSize: "14px" }} />
            </Link>
          </ListItem>
        ))}
        <ListItem button sx={{ display: "block", textAlign: "center", marginTop: 2 }}>
          <Button variant="contained" sx={contactButtonMobileStyle} onClick={() => window.location.href = "/contact"}>
            CONTACTAR <ArrowOutwardIcon sx={{ fontSize: 14, marginLeft: 1 }} />
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="static" color="transparent" elevation={0} sx={navbarStyle}>
      <Toolbar sx={toolbarStyle}>
        <Box sx={leftSectionStyle}>
          <img src={Logo} alt="Logo Hitpoly" style={{ height: "41px", marginTop: "10px" }} />
        </Box>
        <Box sx={{ ...centerSectionStyle, position: "relative" }} onMouseLeave={handleMouseLeave}>
          {hoverIndex !== null && (
            <EfectoSpanHover
              width={itemWidths[hoverIndex] || 0}
              left={calculateLeft(hoverIndex)}
            />
          )}
          {selectedIndex !== null && (
            <EfectoSpan
              width={itemWidths[selectedIndex] || 0}
              left={calculateLeft(selectedIndex)}
            />
          )}
          {navItems.map((item, index) => (
            <Link to={navLinks[index]} key={index} style={navLinkStyle}>
              <Button
                ref={(el) => (itemRefs.current[index] = el)}
                onMouseEnter={() => handleMouseEnter(index)}
                sx={{
                  ...navButtonStyle,
                  zIndex: 99,
                  color:
                    selectedIndex === index || hoverIndex === index
                      ? "#fff"
                      : "#FFFFFF",
                }}
              >
                {item}
              </Button>
            </Link>
          ))}
        </Box>
        <Box sx={rightSectionStyle}>
        
          <Button variant="contained" sx={contactButtonStyle} onClick={() => window.location.href = "/contact"}>
            CONTACTAR <ArrowOutwardIcon sx={{ fontSize: 16, marginLeft: 1 }} />
          </Button>

          <Box sx={{display: "none"}}>
            <MenuDeServicios />
          </Box>
        </Box>
        <Box>
          
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: "block", md: "none" }, color: "#FFFFFF" }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </AppBar>
  );
};

const navItems = ["INICIO", "PORTAFOLIO", "SERVICIOS", "SOBRE NOSOTROS", "BLOG"];
const navLinks = ["/", "/portfolio", "/servicios", "/nosotros", "/blog",];

const navbarStyle = {
  display: "flex",
  padding: "30px 20px",
  maxWidth: "100%",
};

const toolbarStyle = {
  width: "100%",
  maxWidth: "98%",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const leftSectionStyle = {
  flex: 1,
  display: "flex",
  justifyContent: "flex-start",
};

const centerSectionStyle = {
  display: { xs: "none", md: "flex" },
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #ffffff47",
  borderRadius: "25px",
};

const rightSectionStyle = {
  flex: 1,
  display: { xs: "none", md: "flex" },
  justifyContent: "flex-end",
};

const navLinkStyle = {
  textDecoration: "none",
};

const navButtonStyle = {
  padding: "8px 20px",
  borderRadius: "25px",
  fontSize: "12px",
  letterSpacing: "1px",
  transition: "background-color 0.3s ease",
};

const contactButtonStyle = {
  backgroundColor: "#6F4CE0",
  color: "#FFFFFF",
  borderRadius: "25px",
  padding: "8px 20px",
  marginRight: "10%",
  fontSize: "14px",
  fontWeight: "bold",
  letterSpacing: "1px",
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: "#3E1F7E",
  },
};

const contactButtonMobileStyle = {
  backgroundColor: "#6F4CE0",
  color: "#FFFFFF",
  borderRadius: "25px",
  padding: "8px 20px",
  fontSize: "14px",
  fontWeight: "bold",
  letterSpacing: "1px",
  width: "100%",
};

export default Navbar;
