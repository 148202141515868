import React from "react";
import { Box, Card, Container, Typography } from "@mui/material";
import ceo from "../../../assets/ceo.png";
import logoInfo from "../../../assets/logoinfo.png";

const CeoCard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: { xs: 5, lg: 12 },
        width: { lg: "98vw" },
      }}
    >
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            color: "#B51AD8",
            fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" },
          }}
        >
          NUESTRO CEO
        </Typography>

        <Typography
          variant="h3"
          sx={{
            fontWeight: "bold",
            mb: 3,
            color: "#ffffff",
            fontSize: {
              xs: "30px",
              sm: "2.5rem",
              md: "3rem",
              lg: "3.25rem",
            },
          }}
        >
          CONOCE AL FUNDADOR
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
          width: "80%",
        }}
      >
        <Card
          sx={{
            borderRadius: "15px",
            border: "1px solid rgba(255, 255, 255, 0.50)",
            backgroundColor: "#0a0a0a",
            width: { xs: "90%", sm: "80%", md: "50%", lg: "40%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `
              radial-gradient(circle at bottom left, #7311BB 1%, transparent 40%),
              radial-gradient(circle at top right, #7311BB 1%, transparent 40%)
            `,
          }}
        >
          <img
            src={ceo}
            alt="ceo"
            style={{ width: "100%", height: "auto", borderRadius: "15px" }}
          />
        </Card>

        <Box
          sx={{
            padding: { xs: "20px", sm: "30px", md: "40px" },
            color: "#ffffff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: { xs: "100%", sm: "80%", md: "60%" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              mb: 2,
              fontSize: {
                xs: "30px",
                sm: "2.5rem",
                md: "3rem",
                lg: "3.25rem",
              },
            }}
          >
            DENIS OLIVARES
          </Typography>

          <Typography
            variant="body1"
            sx={{
              mb: 2,
              fontSize: { xs: "0.875rem", sm: "1rem", md: "0.9375rem" },
            }}
          >
            Somos una fuerza motriz detrás de la innovación transformadora y el
            cambio social positivo que mejora la economia Latinoamericana y del
            mundo entero. Reconocemos que el futuro pertenece a aquellos que
            pueden adaptarse rápidamente a las demandas del mercado y abrazar la
            innovación disruptiva. Nuestro compromiso con el impacto social
            refleja nuestra creencia en que las empresas tienen la
            responsabilidad de contribuir al bienestar de la sociedad en su
            conjunto. Con un enfoque en la agilidad, la innovación y el impacto
            social, HitPoly se posiciona como líder en la creación de un futuro
            tecnológico más brillante y equitativo para todos.
          </Typography>

          <Box
            sx={{
              width: { xs: "100%", sm: "80%", md: "60%" },
              height: "auto",
              mt: 2,
            }}
          >
            <img
              src={logoInfo}
              alt="logoInfo"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CeoCard;
