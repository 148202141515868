import React from 'react';
import { Box, Typography, Card, Grid } from "@mui/material";


const HomeService = ()=>{
    return (
        <Grid container>
        <Box
          sx={{
            color: "#ffffff",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: { xs: "10px", sm: "20px", md: "40px", xl: "20px 40px" },
            width: "100%",
          }}
        >
  <Card
    sx={{
      borderRadius: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#ffffff",
      padding: { xs: "20px", sm: "30px", md: "40px" },
      marginBottom: {xs: "50px", md: "50px" },
      width: "97%",
      height: {xs: "130px", lg:"230px"},
      textAlign: "center",
      background: "transparent",
      position: "relative", // Agregado para los pseudoelementos
      overflow: "hidden", // Agregado para ocultar el desbordamiento
      border: "1px solid rgba(255, 255, 255, 0.2)",
  
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        width: "450px", // Tamaño aumentado
        height: "450px", // Tamaño aumentado
        borderRadius: "50%",
        background: "radial-gradient(circle, rgba(115, 17, 187, 0.7), transparent 60%)",
        transition: "background 0.3s",
      },
      "&::before": {
        top: "10px", // Posición ajustada
        left: "-180px", // Posición ajustada
      },
      "&::after": {
        bottom: "10px", // Posición ajustada
        right: "-180px", // Posición ajustada
      },
    }}
  >
            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
              }}
            >
              SOLUCIONAMOS PROBLEMAS 
              <Typography
                variant="h3"
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
                }}
              >
                DE IMPACTO INTERNACIONAL
              </Typography>
            </Typography>
          </Card>
        </Box>
      </Grid>
    )
}

export default HomeService;
