import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid as Grid2, Button } from "@mui/material";
import DesarrolloSoftware from "../../../../assets/desarrollodesoftware.png";
import MarketingContenidos from "../../../../assets/marketingContenidos.png";
import TypographyColumns from "../../../components/cabeceras/vertical/verticalUno/VerticalUno";
import ServiceCard from "../../../components/cardDeServicios/cardDeServicios";

const typographyData = [
  {
    variant: "body1",
    color: "#B51AD8",
    textTransform: "uppercase",
    fontSize: { xs: "16px", sm: "18px", md: "18px", lg: "18px" },
    maxWidth: "40%",
    text: "SERVICIOS MÁS DESTACADOS",
    fontWeight: 500,
  },
  {
    variant: "body1",
    textTransform: "uppercase",
    fontSize: { xs: "36px", sm: "42px", md: "52px", lg: "52px" },
    maxWidth: { xs: "80%", md: "60%" },
    text: "SOLUCIONES PARA MARCAS Y EMPRESAS DE IMPACTO GLOBAL",
    fontWeight: "bold",
    lineHeight: {
      xs: "45px",
      sm: "50px",
      md: "58px",
      lg: "58px",
      xl: "58px",
    },
  },
];

const services = [
  {
    id: 1,
    image: DesarrolloSoftware,
    title: "DESARROLLO DE SOFTWARE Y APPS A LA MEDIDA",
    description:
      "Adaptados a las necesidades específicas de tu negocio para optimizar procesos y mejorar la eficiencia.",
    link: "/DesarrolloDeSoftwareAMedida",
  },
  {
    id: 2,
    image: MarketingContenidos,
    title: "MARKETING DE CONTENIDOS",
    description:
      "Potencia tu marca con estrategias personalizadas en SEO, redes sociales, publicidad online y contenido relevante.",
    link: "/ServicioMarketingContenidos",
  },
  {
    id: 3,
    image: MarketingContenidos,
    title: "CAMPAÑAS PUBLICITARIAS EFECTIVAS",
    description:
      "Conecta con tu audiencia en Google y Facebook, resolviendo sus necesidades y captando su atención en el momento justo.",
    link: "/ServicioCampañasPublicitarias",
  },
];

const SectionService = () => {
  return (
    <Box
      sx={{
        padding: "10px 10px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <TypographyColumns data={typographyData} />
      </Box>

      <Box sx={{ maxWidth: "100vw", padding: { xs: "30px", md: "20px 40px" } }}>
        <Grid2 container spacing={5} justifyContent="center" alignItems="center">
          {/* Renderizar las tarjetas de servicios usando ServiceCard */}
          {services.map((service) => (
            <Grid2
              item
              xs={12}
              md={4}
              display="flex"
              justifyContent="center"
              key={service.id}
            >
              <ServiceCard
                image={service.image}
                title={service.title}
                description={service.description}
                link={service.link}
              />
            </Grid2>
          ))}
        </Grid2>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <Button
            component={Link}
            to="/servicios" // Reemplaza `link` con una URL apropiada
            sx={{
              width: { xs: "90%", md: "20%" },
              padding: "10px 20px",
              backgroundColor: "#ECEAEF",
              color: "#B51AD8",
              borderRadius: "25px",
              fontWeight: "bold",
              fontSize: "16px",
              boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
              transition: "all 0.3s ease",
              "&:hover": {
                backgroundColor: "#7311bb",
                color: "#ECEAEF",
                transform: "translateY(-5px)",
              },
              "&:active": {
                boxShadow: "0px 5px 10px rgba(90, 15, 200, 0.2)",
                transform: "translateY(2px)",
              },
            }}
          >
            Ver todos los servicios
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionService;
