import { Box } from "@mui/material";
import Navbar from "../navbarHomeGral/navbarGral";
import CardSection from "./components/CardSection";
import SectionService from "./components//servicios/SectionService";
import ServiceReviews from "./components/ServiceReviews";
import ComunityPublic from "./components/comunitypublic";
import StatisticsSection from "./components/StatisticsSection";
import PortadaHome from "./components/portada/PortadaHome";
import Footer from "../footer/page";

const Home = () => {
  return (
    <Box>
      {/* Barra de navegación */}
      <Navbar />

      {/* Sección de Portada */}
      <Box sx={{ marginTop: "50px", marginBottom: { xs: "50px", md: "10px" } }}>
        <PortadaHome />
      </Box>

      {/* Sección de Tarjetas */}
      <CardSection />

      {/* Sección de Cuidado y Servicio */}
      <SectionService />

      {/* Sección de Reseñas */}
        <ServiceReviews />

      {/* Sección de tarjeta de crecimiento */}
      <StatisticsSection />

      {/* Sección de Comunidad */}
      <ComunityPublic />

      {/* Pie de Página */}
      <Footer />
    </Box>
  );
};

export default Home;
