import React from "react";
import NavGral from "../navbarHomeGral/navbarGral";
import PageCard from "../portfolio/components/PageCard";
import ServicesGrid from "../portfolio/components/ServicesGrid";
import Footer from "../footer/page";
import { Box, Grid2 } from "@mui/material";

const Portfolio = () => {
  return (
   
    <Box
  sx={{
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
  }}
>
  <Grid2
    container
    sx={{ padding: 0, margin: 0, width: '100%' }}
  >
    <NavGral />
    <PageCard />
    <ServicesGrid />
  </Grid2>
  <Footer />
</Box>


  );
};

export default Portfolio;
